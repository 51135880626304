import React, {useState, useEffect} from 'react';
//import axios from 'axios';
//import {BaseURL, Token, hashScrollHandler} from '../utils/utils';
import {hashScrollHandler} from '../utils/utils';

const dehydrated = {
    hero: [] as any[],
    iframeUrl: "https://etsls.sertronics.de/?mli"
}

const Etsls = ({language}: {language: string}) => {
  const [data, setData] = useState(dehydrated);
  const queryParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    async function fetchPage(){
      setData({
        ...data
      })
      hashScrollHandler()
    }
    if( queryParams.get('jobauskunft') != null ) {
      data.iframeUrl = "https://etsls.sertronics.de/jobauskunft?mli=";
      if( queryParams.get('jobnr') != null ) {
        data.iframeUrl += "&jobnr="+queryParams.get('jobnr');
      }
      if( queryParams.get('plz') != null ) {
        data.iframeUrl += "&plz="+queryParams.get('plz');
      }
    }
    fetchPage()

    const bootstrapScript = document.createElement('script');
    bootstrapScript.src = "/tool/bootstrap.bundle.min.js?" + Date.now();
    bootstrapScript.async = true;
    document.body.appendChild(bootstrapScript);

    const bootstrapStyle = document.createElement('link');
    bootstrapStyle.rel = "stylesheet";
    bootstrapStyle.type = "text/css";
    bootstrapStyle.href = "/tool/bootstrap.modal.min.css?" + Date.now();
    document.body.appendChild(bootstrapStyle);

    const clickTabScript = document.createElement('script');
    clickTabScript.src = "/tool/clicktab.min.js?" + Date.now();
    clickTabScript.async = true;
    document.body.appendChild(clickTabScript);

    const iframeScript = document.createElement('script');
    iframeScript.src = "/tool/iframe.min.js?" + Date.now();
    iframeScript.async = true;
    document.body.appendChild(iframeScript);

    hashScrollHandler();
    // eslint-disable-next-line
  }, [language]);
  
  return(
  <div className="shop-content">
    <div className="container container-fluid" id="repair">
      <div className="row">
        <div className="col-xs-12">
          <br/>
          <br/>
          <br/>
          <iframe id="repair-iframe" title="repair-iframe" className="" src={data.iframeUrl} scrolling="no" frameBorder={0}></iframe>
          <div id="iframe-loading" className="">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
          <div className="modal fade" id="repairModal">
            <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Hilfe</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">&times;</button> */}
                </div>
                <div className="modal-body">
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Schließen</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Etsls