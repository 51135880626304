import React, {useState, useEffect} from 'react';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import {Link} from 'react-router-dom';
import axios from 'axios';
import {Hero} from '../components/Hero';
import CountUp from 'react-countup';
import {BaseURL, Token, hashScrollHandler} from '../utils/utils';

interface ISubservice{
  title: string;
  icon: string;
  description: string;
  items: Array<{title: string, description: string, icon: string}>;
  img: string;
}

const dehydrated = {
    hero: [] as any[],
    title: 'Professional Services',
    missionStatement: 'Lorem Ipsum dolor sit amet.',
    partnersImages: [] as any[],
    heading_1: 'Unsere Leistungen im Überblick',
    subservices: [] as any,
    numbers: [] as any
}

const SubServiceCard = (subservice: any) => (
  <div className="subservice">
    <div className={"sub-service-card" + (subservice.selected ? " selected-subservice" : "")} 
      style={{background: 'url('+BaseURL+subservice.img.path+')', backgroundSize: 'cover'}}>
      {/* <img src={BaseURL + subservice.icon.path} alt=""/> */}
      <h4>{subservice.title}</h4>
    </div>
  </div>
)

var isInViewport = function (elem: HTMLElement) {
  if(elem){
    let bounding = elem.getBoundingClientRect();
    return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }
};

const PartnerRow = ({partners}: {partners: Array<{path: string}>}) => {
	const handleDrag = (e: any) => e.preventDefault()
    const onInitialized = (event: any) => {
	    /* console.log(event); */
	}
    const onSlideChanged = (event: any) => {
	    /* console.log(event); */
	}
	const itemsPerSlideConfigs = {
		0: {items: 1},
		500: {items: 2},
		700: {items: 3},
		1024: {items: 4},
		1200: {items: 5},
	};
	return(
		<AliceCarousel 
			mouseTrackingEnabled
			autoPlay
			autoPlayInterval={3000}
			duration={500}
			buttonsDisabled
			dotsDisabled
			fadeOutAnimation
			responsive={itemsPerSlideConfigs}
			onInitialized={onInitialized}
			onSlideChanged={onSlideChanged}
		>
			{partners.map((partner, idx: any) => <img onDragStart={handleDrag} className="partner-img" key={"partner-img-"+idx} src={ BaseURL + "/" + partner.path} alt=""/>)}
		</AliceCarousel>
	)
}

const Services = (props: any) => {
  const language = props.language
  const [data, setData] = useState(dehydrated)
  const [selectedSubservice, setSelectedSubservice] = useState(0)
  const [counting, setCounting] = useState(false)
  const loc = props.location.pathname.split("/").slice(-1)[0]||"professional"

  setTimeout(() => {
    let counterRow = document.getElementById('service-numbers') as HTMLElement
    if(isInViewport(counterRow)){setCounting(true)}
    window.addEventListener("scroll", function scrollListener(){if(isInViewport(counterRow)){
      setCounting(true);
      window.removeEventListener("scroll", scrollListener)
    }})
  }, 1000)

  useEffect(() => {
    async function fetchPage(){
      // Fetch content and santize
      const content = await axios.get(BaseURL + '/cockpit/api/collections/get/services?token='+Token+'&populate=3&lang='+language)
      const sanitized = content.data.entries.filter((entry: any) => entry.link === ("services/" + loc))[0]
      // Set selected subservice (possibly through url hash)
      let subServiceIndex = 0
      const hash = window.location.hash
      if(hash !== '' && sanitized){
        let idx = sanitized.subservices.findIndex((subService: any) => '#'+subService.hashlink === hash)
        if(idx > -1){
          subServiceIndex = idx
          setTimeout(() => selectSubservice(subServiceIndex), 400)
        }
      }
      setSelectedSubservice(subServiceIndex)
      // Setting up the eventlistener for the numbers
      setData({...sanitized})
    }
    fetchPage()
  }, [loc, language])

  useEffect(() => {
    let subServiceIndex = 0
    const hash = window.location.hash
    if(hash !== '' && data.subservices){
      let idx = data.subservices.findIndex((subService: any) => '#'+subService.hashlink === hash)
      if(idx > -1){
        subServiceIndex = idx
      }
    }
    selectSubservice(subServiceIndex)
  }, [props.location.hash, data.subservices])

  const selectSubservice = (idx: number) => {
    hashScrollHandler('subservice-container')
    setSelectedSubservice(idx)
  }
  if(!data.subservices || (data.subservices.length === 0)|| !data.subservices[selectedSubservice]){return null}
  return(
  <div className="service-page">
    <Hero data={data.hero} btn={false} language={language}/>
    <div className="container container-fluid">
      <div className="row center-xs middle-xs">
        <div className="col-xs-12 row center-xs middle-xs">
          <PartnerRow partners={data.partnersImages}/>
        </div>
      </div>
    </div>
    <div className="hued-row">
      <div className="container container-fluid">
        <h2>
          {(language==='de')||(language==='ch') ? "Unsere Leistungen" : ""}
          {language==='en' ? "Our Services" : ""}
          {language==='fr' ? "" : ""}
          {language==='it' ? "" : ""}
        </h2>
        <div className="row center-xs">
          {data.subservices ? data.subservices.map((subservice: any, idx: number) => <div key={subservice.title} onClick={() => {window.location.hash = subservice.hashlink; selectSubservice(idx)}}><SubServiceCard {...{...subservice, selected: selectedSubservice === idx}} /></div>): null}
        </div>
      </div>
    </div>
    <div className="container container-fluid" id="subservice-container">
      <h2>{data.subservices[selectedSubservice].title}</h2>
      <div className="row">
        <div className="col-xs-12 col-md-6">
          <p className="subservice-description" dangerouslySetInnerHTML={{__html: data.subservices[selectedSubservice].description}}/>
          {data.subservices[selectedSubservice].button ? <>
            <p className="subservice-description" dangerouslySetInnerHTML={{__html: data.subservices[selectedSubservice].button_paragraph}}/>
            <div className="col-xs-12 center-xs end-md">
            <Link to={data.subservices[selectedSubservice].button_link}><button style={{marginBottom: "3rem"}} className="main-card-btn">  
              {data.subservices[selectedSubservice].button_text}
              </button></Link>
            </div>
          </> : null}
        </div>
        <div className="col-xs-12 col-md-6 benefit-grid center-xs top-xs">
            {data.subservices[selectedSubservice].items.map((item:any) => (
                <div key={item.value.heading} className="benefit">
                  <div className="benefit-ico">
                    {item.value.icon ? <img width={42} height={42} src={BaseURL + "/" + item.value.icon.path} alt=""/> : ""}
                  </div>
                  <h3>{item.value.heading}</h3>
                  <span>{item.value.description}</span>
              </div>
            ))}
        </div>
      </div>
    </div>
    <div className="numbers-row">
      <div className="container container-fluid">
        <div id="service-numbers" className="row center-xs">
          {data.numbers&&data.numbers.map((number: any, idx: number) => 
            <div className="col-xs-12 col-sm-6 col-md-3 number-entry" key={"number-entry-"+idx}>
              <div>{number.value.uppertext}</div>
              {counting ? <div><CountUp separator={"."} duration={3} end={parseInt(number.value.number)}/></div> : <div>0</div>}
              <div><b>{number.value.lowertext}</b></div>
            </div>
          )}
        </div>
      </div>
    </div>
    <div className="container container-fluid">
    <h3 className="blue-heading"> {(language==='de')||(language==='ch') ? "Kontakt" : ""}</h3>
      <h2>              {(language==='de')||(language==='ch') ? "Ansprechpartner" : ""}
              {language==='en' ? "Contact" : ""}
              {language==='fr' ? "Contact" : ""}
              {language==='it' ? "Contatto" : ""}</h2>
      <div className="row center-xs">
        {data.subservices[selectedSubservice].contact.map((contact: any) => (
          <div key={"contact"+contact.name} className="col-xs-12 col-sm-6 col-md-4 contact-card-wrapper">
            <div className="contact-card start-xs">
            <div className="contact-upper">
              <img src={BaseURL + contact.img.path} alt=""/>
              <div>
                <b>{contact.name}</b>
                <div>{contact.title}</div>
              </div>
            </div>
            <div>
              <div>Mail: <a href={"mailto:"+ contact.mail}>{contact.mail}</a></div>
              <div>Tel: <a href={"tel:"+ contact.tel.toString().replace(/\s/g, '').replace(/-/g, '')}>{contact.tel}</a></div>
            </div>
            </div>
          </div>
        ))}
        
      </div>
    </div>
  </div>
  )
}

export default Services