import React, {useState} from 'react';
import la from '../images/left-arrow.svg';
import ra from '../images/right-arrow.svg';
import {BaseURL} from '../utils/utils'

export interface ITestimonial{
  text: string;
  img: string;
  name: string;
  title: string;
}

const Testimonial = ({testimonials}: any) => {
  const [idx, changeIdx] = useState(0)
  if(testimonials.length===0){return null}
  return (
  <div className="testimonial-container">
    <div className="testimonial-wrapper">
      <div className="testimonial-bg">
          <div className="testimonial-content">
            <p className="testimonial-text">{testimonials[idx].text}</p>
            <img src={BaseURL + testimonials[idx].img.path} width={45} height={45} style={{float: 'left'}}alt=""/>
            <b>{testimonials[idx].name}</b> <br/>
            <span>{testimonials[idx].title}</span>
            
          </div>
        </div>
    </div>
    <div className="row right-xs">
      <div className="col-xs-12 end-xs">
        <button className="next-prev-btn" onClick={() => changeIdx((idx-1) > -1 ? (idx-1) : testimonials.length-1)}> <img width="24" height="24" src={la} alt="<"/> </button>
        <button className="next-prev-btn" onClick={() => changeIdx((idx+1)%testimonials.length)}> <img width="24" height="24" src={ra} alt="<"/> </button>
      </div>
    </div>
  </div>
  )
}

export default Testimonial;