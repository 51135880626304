import React from 'react';
import { Link } from 'react-router-dom'

interface IProps{
  title: string;
  text: string;
  link: string;
  language: string;
}

const MainCard = ({title, text, link, language}: IProps) => {
  return(
    <div className="main-card-wrapper col-sm-12 col-md-4">
      <div className="main-card">
      <div className="main-card-stripe"/>
      <h2>{title}</h2>
      <p>{text}</p>
      <Link to={link}><button className="main-card-btn">  {(language==='de')||(language==='ch') ? "Mehr erfahren" : ""}
            {language==='en' ? "Find out more" : ""}
            {language==='fr' ? "Apprendre plus" : ""}
            {language==='it' ? "Saperne di più" : ""}</button></Link>
    </div>
    </div>
  )
}

export default MainCard