import React, {useEffect, useState} from 'react';
import './fonts/ibm-plex-sans.css';
import './App.css';
import './flexboxgrid.min.css';
import {BrowserRouter as Router, Route, Switch, withRouter} from 'react-router-dom'
import TopBar from './components/TopBar';
import Footer from './components/Footer';
import LandingPage from './pages/LandingPage';
import Shops from './pages/Shops';
import HelpPoint from './pages/HelpPoint';
//import Repair from './pages/Repair';
//import Career from './pages/Career';
import Services from './pages/Services';
import smoothscroll from 'smoothscroll-polyfill';
import Legal from './pages/Legal';
//import Carrier from './pages/Carrier';
//import Retour from './pages/Retour';
import Etsls from './pages/Etsls';
import NotFound from './pages/NotFound';
// kick off the polyfill!
smoothscroll.polyfill();

let STT = ({ children, location: { pathname } }: any) => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return children;
};

const ScrollToTop = withRouter(STT);

const App = () => {
	const host = window.location.host
	const [lang, setLang] = useState(host.search('.ch') !== -1 ? 'ch' : (host.search('.com') !== -1 ? 'en' : 'de'))
	return (
		<div className="App">
			<Router>
				<ScrollToTop>
					<TopBar language={lang} setLang={lang => setLang(lang)} />
					<Switch>
						<Route path="/" exact component={() => <LandingPage language={lang} />} />
						<Route path="/services" render={(props: any) => <Services {...props} language={lang}/>} />
						<Route path="/shops" exact component={() => <Shops language={lang}/>} />

						{/* <Route path="/help-point" exact component={() => <HelpPoint language={lang}/>} /> */}
						<Route path="/help-point/etsls" exact component={() => <Etsls language={lang}/>} />
						<Route path="/help-point" render={(props: any) => <HelpPoint {...props} language={lang}/>} />
						{/* <Route path="/help-point/dpd" exact component={() => <Carrier language={lang} type={"dpd"}/>} />*/}
						{/* <Route path="/help-point/dhl" exact component={() => <Carrier language={lang} type={"dhl"}/>} />*/}
						{/* <Route path="/help-point/repair" exact component={() => <Repair language={lang}/>} /> */}
						{/* <Route path="/help-point/retour" exact component={() => <Retour language={lang}/>} /> */}
						{/* <Route path="/career" exact component={() => <Career language={lang}/>} /> */}
						<Route path="/terms" exact component={() => <Legal language={lang} type={"tos"}/>} />
						<Route path="/privacy" exact component={() => <Legal language={lang} type={"privacy"}/>} />
						<Route path="/disclaimer" exact component={() => <Legal language={lang} type={"imprint"}/>} />
						<Route path="*" exact component={() => <NotFound language={lang}/>} />
					</Switch>
					<Footer language={lang}/>
				</ScrollToTop>
			</Router>
		</div>
	);
}

export default App;