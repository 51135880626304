import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {BaseURL, Token} from '../utils/utils';

const Legal = ({language, type}: {language: string, type: string}) => {
  const [data, setData] = useState({content: ""})
  const fetchPage = async() => {
    const endpoint = await axios.get(BaseURL + '/cockpit/api/singletons/get/'+type+'?token='+Token+'&lang='+language);
    setData({...data, content: endpoint.data.content})
  }
  useEffect(() => {fetchPage()})
  return(<div className="legal-page">
    <div className="container container-fluid">
      <div className="row">
        <div className="col-xs-12">
          <br/>
          <br/>
          <br/>
          <div dangerouslySetInnerHTML={{__html: data.content}}/>
        </div>
      </div>
    </div>
  </div>)
}

export default Legal