import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {BaseURL} from '../utils/utils';
interface IHero{
  img: {path: string};
  heading: string;
  mission_statement: string;
  button: boolean;
  link: string;
}


export const Hero = ({data, btn, language}: {data: IHero[], btn: undefined|boolean, language: string}) => {
  let [idx, setIdx] = useState(0)
  useEffect(()=>{
    const interval = setInterval(()=>{
      return setIdx(index => (index + 1)%data.length)
    }, 4000)
    return () => {clearInterval(interval)}
  }, [data])
  return (
    <div className="hero">
      {data.map((instance: any, index: any) => (
        <div
          key={instance._id}
          className="hero-instance"
          style={{
            backgroundImage: 'url('+BaseURL+instance.img.path+')',
            opacity: idx === index ? 1 : 0,
            pointerEvents: idx !== index ? 'none' : 'auto'
          }}
        >
          <div className="container container-fluid hero-container">
            <div className="hero-text-block">
              <h1>{instance.heading}</h1>
              {instance.mission_statement.length > 0 ? <p>{instance.mission_statement}</p> : null}
              {(btn&&instance.button) ? <Link to={instance.link}><button style={{marginBottom: '20px'}} className="main-card-btn">
              {(language==='de')||(language==='ch') ? ( instance.link.indexOf("#contact") !== -1 ? "Kontaktieren Sie uns" : "Mehr erfahren" ) : ""}
            {language==='en' ? ( instance.link.indexOf("#contact") !== -1 ? "Contact us" : "Find out more" ) : ""}
            {language==='fr' ? ( instance.link.indexOf("#contact") !== -1 ? "Contact us" : "Apprendre plus" ) : ""}
            {language==='it' ? ( instance.link.indexOf("#contact") !== -1 ? "Contact us" : "Saperne di pi�" ) : ""}
                </button></Link> : null}
            </div>
            {data.length > 1 ? <div className="hero-navigator">
              {data.map((item: any, index: number) => <div onClick={() => setIdx(index)} className={"navigator-cirle "+(index===idx ? "navigator-circle-active": "")} key={"navigator-idx-"+index}></div>)}
            </div> : null}
          </div>
        </div>
      )) }
    </div>
  )
}

export default null